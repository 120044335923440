<template>
  <div style="padding-top:120px;">
    <el-card class="box">
      <div slot="header" class="clearfix">
        <span>页面/资源未找到 (404 not found)</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="$router.back(-1)">返回上一页</el-button>
      </div>
      <p>您所访问的页面或资源未找到，请确认您访问的路径是正确的</p>
      <p class="english">The loaded page or resource does not exist,Please confirm that the path you are visiting is
        correct</p>
      <p>如果确认无误</p>
      <p class="english">If the path is correct</p>
      <p>极有可能访问对象已经被删除</p>
      <p class="english">It is possible that the resource has been deleted</p>
      <p>您可以联系管理员咨询，或者返回上一页</p>
      <p class="english">You can contact the website administrator Or go back to the previous page</p>
      <p>点击<b @click="$router.back(-1)">这里</b>返回上一页</p>
      <p class="english">Click <b @click="$router.back(-1)">here</b> to return to the previous page</p>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "notFound",
};
</script>

<style lang="scss" scoped>
.box {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 40px;
  p {
    text-align: center;
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
    b {
      cursor: pointer;
    }
  }
  .english {
    margin: 3px;
    color: #666;
    font-size: 14px;
  }
}
</style>